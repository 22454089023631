import { NewsService, NewsStatus, Scope } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultCatch } from '@common/defaults';
import { ItemsPerPage } from '@common/types/ItemsPerPage';
import { getIncrementalNextPage } from '@web/utils/getIncrementalNextPage';

export const fetchNews = async ({
    contextData,
    currentPage = 1,
    isClientSide,
    newsCatID,
    perPage = ItemsPerPage.STANDARD,
    search,
    tagID,
    isCompactMode,
    includeBinaryPolls,
}: {
    contextData: ContextData;
    currentPage?: number;
    isClientSide: boolean;
    newsCatID?: number;
    perPage?: number;
    search?: string;
    tagID?: number;
    isCompactMode?: boolean;
    includeBinaryPolls?: boolean;
}) => {
    const request = new ApiBaseRequest(contextData, isClientSide);
    const newsService = new NewsService(request);

    const { nextPage, nextPerPage } = getIncrementalNextPage(currentPage, perPage);
    const response = await newsService
        .listNewsByDomainId({
            contextId: contextData.context.id,
            experimentGroup: contextData.experimentGroup,
            scope: Scope.PUBLIC,
            perPage: nextPerPage,
            page: nextPage,
            checkNextPage: true,
            newsStatus: NewsStatus.PUBLISHED,
            newsCatId: newsCatID,
            tagId: tagID,
            search: search,
            isCompactMode: isCompactMode,
            includeBinaryPolls: includeBinaryPolls,
        })
        .catch(defaultCatch);

    return {
        data: response?.data || [],
        pagination: response?.pagination,
    };
};
