import { FC } from 'react';

import { useTranslation } from '@pxr/i18n';
import { ComponentList } from '@web/molecules/ComponentList/ComponentList';
import { SimpleWidgetConfig } from '@web/organisms/SimpleWidget/types';

import { Tab } from '../Tab';

import styles from './Tabs.module.scss';

export interface TabProps {
    tabs: Array<SimpleWidgetConfig>;
    onTabClick: <T extends string>(x: T) => void;
    highlighted?: SimpleWidgetConfig;
}

export const Tabs: FC<TabProps> = ({ tabs, onTabClick, highlighted }) => {
    const __sidebar = useTranslation('sidebar').t;
    return (
        <ComponentList
            classes={styles.Tabs}
            ComponentItem={Tab}
            propsList={tabs.map(({ icon, title, metaKey }: SimpleWidgetConfig) => ({
                label: icon ? icon : __sidebar(title),
                dataKey: metaKey,
                highlighted: metaKey === highlighted?.metaKey,
                onTabClick: () => onTabClick(metaKey),
            }))}
        />
    );
};
